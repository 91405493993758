import * as React from "react"
import { gql, useApolloClient } from "@apollo/client"
import Head from "next/head"
import Link from "next/link"

import { ACCESS_TOKEN, REFRESH_TOKEN_KEY } from "lib/config"
import type { MeQuery } from "lib/graphql"
import { MeDocument, useLoginMutation, useSendVerificationMutation } from "lib/graphql"
import { useForm } from "lib/hooks/useForm"
import { useMutationHandler } from "lib/hooks/useMutationHandler"
import { useBetterTranslation } from "lib/hooks/useTranslation"
import type { default as yup } from "lib/yup"
import { default as Yup } from "lib/yup"
import { AuthCheck, AuthLayout } from "components/AuthLayout"
import { Form } from "components/Form"
import { FormError } from "components/FormError"
import { Input } from "components/Input"
import { Modal } from "components/Modal"
import { DownloadApp } from "components/DownloadApp"
import {
  Box,
  Button,
  Center,
  Flex,
  PinInputField,
  PinInput,
  Spinner,
  Stack,
  useMediaQuery,
  useDisclosure,
  useBoolean,
} from "@chakra-ui/react"

const _ = gql`
  mutation Login($data: LoginInput!, $code: String) {
    login(data: $data, code: $code) {
      user {
        ...Me
      }
      token
      refreshToken
    }
  }
  mutation SendVerification($data: LoginInput!) {
    sendVerification(data: $data) {
      skip2fa
    }
  }
`

const SendVerificationSchema = Yup.object().shape({
  email: Yup.string().email("Ongeldig emailadres").required("Verplicht"),
  password: Yup.string().min(8, "Moet minimaal 8 tekens lang zijn").required("Verplicht"),
})

export default function Login() {
  const client = useApolloClient()
  const [isMobile] = useMediaQuery("(max-width: 500px)")
  const modalProps = useDisclosure()
  const [login, { loading: loginLoading }] = useLoginMutation()
  const [isVerifing, setIsVerifing] = React.useState(false)
  const bt = useBetterTranslation()
  const [isDisabled, { on, off }] = useBoolean()
  const sendVerificationForm = useForm({ schema: SendVerificationSchema })
  const [sendVerification, { loading: sendVerificationLoading }] = useSendVerificationMutation()

  const handleSendVerification = (data: yup.InferType<typeof SendVerificationSchema>) => {
    if (isDisabled) return
    on()
    setTimeout(off, 10000)
    return sendVerificationForm.handler(() => sendVerification({ variables: { data } }), {
      onSuccess: (data) => {
        if (data.sendVerification.skip2fa) {
          handleLogin()
        } else {
          modalProps.onOpen()
        }
      },
    })
  }

  const handler = useMutationHandler()

  const handleLogin = (code?: string) => {
    setIsVerifing(true)
    const data = sendVerificationForm.getValues() as yup.InferType<typeof SendVerificationSchema>
    return handler(() => login({ variables: { data, code } }), {
      onSuccess: async (data) => {
        localStorage.setItem(ACCESS_TOKEN, data.login.token)
        await fetch("/api/login", {
          method: "post",
          body: JSON.stringify({ [REFRESH_TOKEN_KEY]: data.login.refreshToken }),
        })
        client.writeQuery<MeQuery>({ query: MeDocument, data: { me: data.login.user } })
      },
      onFinish: () => setIsVerifing(false),
    })
  }

  return (
    <Center flexDir="column" pt={10}>
      <Head>
        <title>Grey Men - Login</title>
      </Head>
      <Box>
        {!isMobile ? (
          <Box w={["100%", 400]}>
            <Form onSubmit={handleSendVerification} {...sendVerificationForm}>
              <Stack spacing={2}>
                <h1 className="text-4xl">Login</h1>
                <Input
                  name="email"
                  label={bt({ en: "Email", nl: "Emailadres" })}
                  placeholder="jim@gmail.com"
                />
                <Input
                  name="password"
                  label={bt({ en: "Password", nl: "Wachtwoord" })}
                  type="password"
                  placeholder="********"
                />
                <Button colorScheme="pink" type="submit" w="100%" isLoading={sendVerificationLoading}>
                  Login
                </Button>
                <FormError />
                <Flex justify="space-between" _hover={{ textDecoration: "underline" }}>
                  <Link href="/forgot-password">
                    {bt({ en: "Forgot password", nl: "Wachtwoord vergeten?" })}
                  </Link>
                </Flex>
              </Stack>
            </Form>
            <Modal title="Verify" isCentered {...modalProps}>
              <div className="space-y-6">
                <p>
                  {bt({
                    en: "A code has been sent to your mobile via sms, enter here to continue.",
                    nl: "Een code is naar je mobiel toegestuurd via sms, druk hier om verder te gaan.",
                  })}
                </p>
                <div className="flex items-center justify-center space-x-2">
                  {isVerifing ? (
                    <Spinner />
                  ) : (
                    <PinInput colorScheme="pink" otp size="lg" onComplete={handleLogin}>
                      <PinInputField autoFocus />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                    </PinInput>
                  )}
                </div>
                <hr />
                <div className="vstack">
                  <p>{bt({ en: "Didn't receive a code?", nl: "Geen code ontvangen?" })}</p>
                  <Button
                    onClick={() =>
                      handleSendVerification(
                        sendVerificationForm.getValues() as yup.InferType<typeof SendVerificationSchema>,
                      )
                    }
                    isDisabled={isDisabled || isVerifing || loginLoading || sendVerificationLoading}
                    isLoading={sendVerificationLoading}
                    variant="outline"
                  >
                    {bt({ en: "Resend", nl: "Opnieuw versturen" })}
                  </Button>
                </div>
              </div>
            </Modal>
          </Box>
        ) : (
          <DownloadApp />
        )}
      </Box>
    </Center>
  )
}

Login.getLayout = (page: React.ReactNode) => (
  <AuthCheck>
    <AuthLayout>{page}</AuthLayout>
  </AuthCheck>
)
